<template>
  <div class="content">
    <div class="tabs">
      <el-tabs v-model="newsType" @tab-click="handleClick">
        <el-tab-pane label="媒体新闻" name="media"></el-tab-pane>
        <el-tab-pane label="公司新闻" name="company"></el-tab-pane>
      </el-tabs>
    </div>
    <MediaNews v-if="newsType == 'media'" class="media"></MediaNews>
    <CorporateNews v-if="newsType == 'company'" class="company"></CorporateNews>
  </div>
</template>

<script>
import MediaNews from './MediaNews.vue';
import CorporateNews from './CorporateNews.vue';
export default {
  components: {
    MediaNews,
    CorporateNews,
  },
  data() {
    return {
      newsType: 'media',
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.name, this.activeName, event);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-size: 1rem;
  color: #333333;
  .tabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e4e6e8;
    margin-top: 2rem;
    /deep/ .el-tabs__nav-wrap::after {
      position: static !important;
    }
    /deep/.el-tabs__item {
      font-size: 1.75rem;
      font-family: Source Han Sans CN;
      // font-weight: bold;
      color: #333;
    }
    /deep/.el-tabs__header {
      margin: 0;
    }
    // /deep/ .el-tabs__active-bar {
    //   background-color: #ffffff;
    // }
  }
  .media {
    margin-top: 1.3125rem;
  }
  .company {
    margin-top: 2.5rem;
  }
}
</style>
