<template>
  <div class="app-main supplier">
    <!-- 供应商管理首页头部 开始 -->
    这是第二个页面
  </div>
</template>

<script>
export default {
  name: 'View',
  data() {
    return {};
  },
  methods: {
    handleAdd() {},
  },
};
</script>

<style></style>
