<template>
  <div class="item">
    <div class="newsItem">
      <div class="title">{{ info.title }}</div>
      <div class="content">{{ info.content }}</div>
    </div>
    <div class="bottomBox">
      <a :href="info.link" class="link" target="_blank">查看详细</a>
      <div class="datebox">
        <div class="month">{{ info.month }}</div>
        <div class="year">{{ info.year }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.item {
  width: 21.5rem;
  // height: 16.1875rem;
  border: 1px solid #e4e6e8;
  // position: relative;
  .newsItem {
    width: 100%;
    padding: 1.1875rem 1.4375rem 0 1.125rem;

    .title {
      width: 100%;
      border-bottom: 1px solid #e4e6e8;
      // padding-bottom: 1.1875rem;
      font-size: 1.0625rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      // text-align: center;
      height: 5.375rem;
      line-height: 2.6875rem;
    }
    .content {
      width: 18.125rem;
      // height: 4.375rem;
      line-height: 1.8125rem;
      margin-top: 1.3125rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /*行数*/
      -webkit-box-orient: vertical;
      font-size: 1rem;
      // text-align: center;
    }
  }
  .bottomBox {
    display: flex;
    justify-content: space-between;
    padding-left: 0.875rem;
    margin-top: 2.375rem;
  }
  .link {
    text-decoration: none;
    color: #333333;
    border: 1px solid #666666;
    font-size: 0.9375rem;
    text-align: center;
    // height: 2rem;
    align-self: center;
    // line-height: 2rem;
    padding: 0.5rem 0.625rem;
    // position: relative;
    // left: 1.14rem;
    // bottom: 0.75rem;
    border-radius: 4px;
    display: inline-block;
  }
  .datebox {
    // position: relative;
    // right: 0;
    // bottom: 0;
    .month {
      border: 1px solid #e4e6e8;
      font-size: 0.875rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding: 0.375rem 0.6875rem 0.1875rem;
      text-align: center;
    }
    .year {
      background: #2168ff;
      padding: 0.3125rem 0.6875rem 0.25rem;
      text-align: center;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.item:hover {
  border: 1px solid #2168ff;
}
</style>
