<template>
  <div class="content">
    <div v-for="(item, index) in newList" :key="index" class="list">
      <Item :info="item"></Item>
    </div>
  </div>
</template>

<script>
import Item from './Item.vue';
export default {
  components: {
    Item,
  },
  data() {
    return {
      newList: [
        {
          title: '“云上海曙，智造甬城”——2023年海曙区创新创业大会·云上智能峰会成功...',
          content: '4月26日，“科技回归都市，人才创造卓越”2023年海曙区创新创业大会在甬水河畔的中都开元名都大酒店大召开.....',
          year: '2023',
          month: '04 28',
          link: 'https://www.sohu.com/a/670989188_100016644',
        },
        {
          title: '何积丰院士出席人工智能大会：推动通感一体车路互联技术应用服务智慧...',
          content: '第六届上海人工智能大会于4月15日到16日在上海举办。本次大会受中国科学技术协会指导，联合多家单位主办.....',
          year: '2023',
          month: '04 15',
          link: 'http://www.cctv-city.com/part-113/7478.html',
        },
        {
          title: '或迎爆发性拐点！并非简单工具箱，未来的可信AI能帮我们做决策',
          content: '2022世界人工智能大会“可信人工智能技术及产业化”论坛今天在上海世博中心举行，与会嘉宾围绕支持产业.....',
          year: '2022',
          month: '09 03',
          link: 'https://baijiahao.baidu.com/s?id=1742958348577456895&wfr=spider&for=pc',
        },
        {
          title: '未来已至！何积丰院士发表《可信人工智能的展望》的主旨报告',
          content: '由何积丰院士牵头的“可信人工智能技术及产业化”论坛，于2022年9月3日在上海世博中心召开的2022世界人工智能.....',
          year: '2022',
          month: '09 13',
          link: 'https://www.163.com/dy/article/HH4V7H8G0553T9KJ.html',
        },
        {
          title: '何积丰院士：人工智能需要规则性治理',
          content: '人工智能作为赋能技术，需要一个治理的阶段。人工智能应用发展迅速，有些在智慧城市建设领域起着核心作用，也.....',
          year: '2021',
          month: '07 11',
          link: 'https://m.thepaper.cn/rss_newsDetail_13535963',
        },
        {
          title: '院士专家会集上海，对话人工智能赋能数字经济',
          content: '5月24日下午，“人工智能为数字经济赋能”主题论坛暨揭牌仪式在上海成功举办。中国科学院上海分院院长、中国科学院院士.....',
          year: '2019',
          month: '05 29',
          link: 'https://www.163.com/dy/article/EGCCTHHU0511DC1H.html',
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  // font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  .list {
    margin-bottom: 2.4375rem;
  }
}
</style>
