import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import Home from '../views/home'
import View from '../views/view'
import ViewTwo from '../views/viewtwo'
export default new Router({
  routes: [
    {
      path: "/",   // path 设置为 “/” ，默认显示该页面
      component: Home,
      name: "Home"
    },
    {
      path: "/view",
      component: View,
      name: "View"
    },
    {
      path: "/viewtwo",
      component: ViewTwo,
      name: "ViewTwo"
    }
  ],
  // mode: "history"    // mode 设置为history ，去掉地址栏上的 # 号
})