<template>
  <div class="container">
    <div class="background" :style="bg">
      <div class="header">
        <div class="core">
          <div class="left">
            <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/logo.png" alt="" class="img" />
            <div class="name">上海华科智谷人工智能研究院</div>
          </div>
          <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="首页" name="first"></el-tab-pane>
              <el-tab-pane label="科学研究" name="second"></el-tab-pane>
              <el-tab-pane label="新闻中心" name="third"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <Main v-if="activeName == 'first'"></Main>
      <Scientific v-if="activeName == 'second'"></Scientific>
      <News v-if="activeName == 'third'"></News>
    </div>
    <!-- <div class="footerbox"> -->
    <div class="footer">
      <div class="touch">联系我们</div>
      <div class="mail">邮箱：contact_jfai@163.com</div>
      <div class="beianhao">备案号：沪ICP备2023014525-1</div>
      <div class="beianhao police">
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/police.png" alt="" class="imgicon" />
        沪公网安备31011302007339号
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import Main from './cmp/Main.vue';
import Scientific from './cmp/Scientific.vue';
import News from './cmp/News.vue';
// import background from 'https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/background.png';
// import backgroundTwo from 'https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/backgroundTwo.png';
// import backgroundThird from 'https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img//backgroundThird.png';

export default {
  name: 'Home',
  components: {
    Main,
    Scientific,
    News,
  },
  data() {
    return {
      background: 'https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/background.png',
      backgroundTwo: 'https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/backgroundTwo.png',
      backgroundThird: 'https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/backgroundThird.png',
      activeName: 'first',
      bg: { background: "url('https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/background.png')" },
    };
  },
  methods: {
    handleAdd() {
      this.$router.push({
        path: `/view`,
      });
    },
    handleClick(tab, event) {
      if (tab.name == 'first') {
        this.bg = { background: `url(${this.background})` };
      }
      if (tab.name == 'second') {
        this.bg = { background: `url(${this.backgroundTwo})` };
      }
      if (tab.name == 'third') {
        this.bg = { background: `url(${this.backgroundThird})` };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  // position: relative;
  // padding-bottom: 1.25rem !important;
  .background {
    height: 43.5rem;
    width: 100%;
    // background: red;
    // background: url('~@/assets/background.png');
    background-repeat: no-repeat !important;
    background-size: cover !important;

    .header {
      padding: 1.875rem 0;
      background-color: rgba(0, 0, 0, 0.35);
      box-sizing: border-box;
      text-align: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      height: 5.3125rem;
      align-items: center;
      .core {
        width: 60.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .left {
        display: flex;
        align-items: center;
        img {
          width: 2.75rem;
          height: 2.9375rem;
        }
        .name {
          font-size: 1.75rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          z-index: 99999;
          margin-left: 1rem;
        }
      }
      .tabs {
        /deep/ .el-tabs__header {
          margin: 0;
        }
        /deep/ .el-tabs__nav-wrap::after {
          position: static !important;
        }
        /deep/.el-tabs__item {
          font-size: 1.25rem;
          font-family: Source Han Sans CN;
          color: #ffffff;
        }
        /deep/ .el-tabs__active-bar {
          background-color: #ffffff;
        }
      }
    }
  }
  .main {
    // padding: 0 26.25rem;
    width: 67.3125rem;
    // text-align: center;
    margin: 0 auto;
  }
  .footer {
    width: 100%;
    height: 11.3125rem;
    background: #1547b0;
    // position: fixed;
    // bottom: 0;
    z-index: 9999999 !important;
    margin-top: 1rem;
    .beianhao {
      // width: 219px;
      // height: 18px;
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 2rem;
      text-align: center;
      // margin-top: 2rem;
    }
    .police {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .imgicon {
      margin-right: 0.16rem;
    }
    .touch {
      font-size: 1.75rem;
      font-family: Source Han Sans CN;
      color: #ffffff;
      // line-height: 1.5625rem;
      // height: 1.5625rem;
      padding-top: 2.5rem;
      padding-left: 26.375rem;
    }
    .mail {
      font-size: 1.25rem;
      font-family: Source Han Sans CN;
      color: #ffffff;
      // line-height: 25px;
      padding-left: 29.5rem;
    }
  }
  .footerbox {
    height: 11.3125rem;
  }
}
</style>
