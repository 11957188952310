<template>
  <div class="content">
    <div class="centerStyle">
      <div class="tabContainer">
        <el-tabs v-model="activeType">
          <el-tab-pane label="车路互联网技术" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="bgimg" :style="bg1">
        <div class="text">服务于智慧城市、智慧交通场景建设，围绕数字底座关键技术研究和产品开发，致力于打造基于数据中台的AI赋能、算力支撑以及交通信创等方向的系统架构；打造“车，路，边，云，网，图“一体化体系，推动智慧城市基础设施与智能网联汽车协同发展。</div>
      </div>
    </div>
    <div class="centerStyle">
      <div class="tabContainer">
        <el-tabs v-model="activeType">
          <el-tab-pane label="零信任安全技术" name="first"> </el-tab-pane>
        </el-tabs>
      </div>
      <div class="bgimg" :style="bg2">
        <div class="text1">零信任架构的关键技术研究，制定整体解决方案，试点示范和推广。</div>
      </div>
    </div>
    <div class="centerStyle">
      <div class="tabContainer">
        <el-tabs v-model="activeType">
          <el-tab-pane label="自适应安全防护关键技术研究" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="bgimg" :style="bg3">
        <div class="text2">以主动防护为核心理念，以科学的安全防护能力评价体系、自适应安全防护框架为基础，构建自适应的安全防护体系。研发云边端一体的动态安全防护系统模型，为海量终端接入及应用提供统一安全标准和动态治理框架。</div>
      </div>
    </div>
    <div class="centerStyle">
      <div class="tabContainer">
        <el-tabs v-model="activeType">
          <el-tab-pane label="车控操作系统" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="bgimg" :style="bg4">
        <div class="text3">基于国际汽车电子开放系统架构标准（AUTOSAR），开展国产车控操作系统及部分工具的技术研究、及应用。</div>
      </div>
    </div>
  </div>
</template>

<script>
import bgimg1 from '@//assets/bgimg1.png';
import bgimg2 from '@//assets/bgimg2.png';
import bgimg3 from '@//assets/bgimg3.png';
import bgimg4 from '@//assets/bgimg4.png';
export default {
  data() {
    return {
      activeType: 'first',
      bg1: { background: `url('https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/bgimg1.png')` },
      bg2: { background: `url('https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/bgimg2.png')` },
      bg3: { background: `url('https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/bgimg3.png')` },
      bg4: { background: `url('https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/bgimg4.png')` },
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-size: 1rem;
  color: #333333;
  .type {
    text-align: center;
  }
  .centerStyle {
    display: flex;
    justify-content: center;
    width: 67.5rem;
    flex-direction: column;
    align-items: center;
    margin-top: 3.125rem;
    .tabContainer {
      border-bottom: 1px solid #e4e6e8;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    /deep/.el-tabs__item {
      font-size: 1.75rem;
      font-family: Source Han Sans CN;
      color: #333;
    }
    /deep/ .el-tabs__header {
      margin: 0;
    }
    .bgimg {
      width: 100%;
      height: 28.125rem;
      // background: url('~@/assets/bgimg1.png');
      background-repeat: no-repeat !important;
      background-size: cover !important;
      margin-top: 1.875rem;
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      // font-weight: 500;
      color: #ffffff;
      line-height: 2.5rem;
      position: relative;
      .text {
        width: 33.5rem;
        height: 7.375rem;
        position: absolute;
        bottom: 5.5rem;
        right: 0.75rem;
        text-indent: 2em;
        font-size: 1.25rem;
      }
      .text1 {
        width: 32.375rem;
        height: 2.6875rem;
        position: absolute;
        bottom: 3rem;
        right: 1.8125rem;
        text-indent: 2em;
        font-size: 1.25rem;
      }
      .text2 {
        width: 33.875rem;
        height: 5.8125rem;
        position: absolute;
        bottom: 5rem;
        left: 4.0625rem;
        text-indent: 2em;
        font-size: 1.25rem;
      }
      .text3 {
        width: 33.25rem;
        height: 2.6875rem;
        position: absolute;
        bottom: 3.5rem;
        right: 1.375rem;
        text-indent: 2em;
        font-size: 1.25rem;
      }
    }
  }
}
</style>
