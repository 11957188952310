// 引入Vue
import Vue from 'vue'
// 引入App
import App from './App.vue'
// 第一步: 引入router 插件
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/base.css';
import './utils/rem.js'
// 使用插件
Vue.use(VueRouter)
Vue.use(ElementUI);
// 第二步: 创建router文件夹 引入实例
import router from './router'

// 关闭生产提示
Vue.config.productionTip = false


// 创建vm
new Vue({
  // 指定容器
  el: '#app',
  // 解析App模板
  render: h => h(App),
  // 使用路由
  router
})