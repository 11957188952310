<template>
  <div class="content">
    <div class="title">“何积丰院士工作室”挂牌仪式在汽车城举行</div>
    <div class="newsBox">
      <div class="left">
        <div>2023年5月18日，“何积丰院士工作室”挂牌仪式在汽车城大厦举行。计算机软件专家、中国科学院院士、上海华科智谷人工智能研究院院长何积丰，汽车城集团党委书记、董事长陈钢出席揭牌仪式。</div>
        <div>“何积丰院士工作室”的成立，意味着以何积丰院士为主导，结合生态链联盟企业共同打造的用于支撑数字底座的智脑系统，包括算法中台、感知认知、边端算力支撑等领先技术的高可信智算团队正式落地嘉定汽车城院士成果转化中心，助力嘉定智能软件产业高质量发展。</div>
      </div>
      <div class="right">
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/twoPerson.png" alt="" />
      </div>
    </div>
    <div class="title spance">“可信智能技术及产业化”论坛</div>
    <div class="artile">
      <div>2022年9月3日下午，在上海世博中心召开了2022世界人工智能大会“可信智能技术及产业化”论坛。本论坛以“可信人工智能技术如何赋能实体产业”为主题，以支持产业人工智能的发展瓶颈和关键问题为抓手，研讨人工智能技术在鲁棒性、可解释性、安全一致性等前沿理论和关键技术，共同探讨可信人工智能技术如何完成“四个面向”的科技发展要求，实现对创新驱动与产业发展的保障。</div>
      <div>
        本次大会由世界人工智能大会组委会办公室指导，何积丰院士号召发起，由中国电科数字有限公司、可信嵌入式系统国家工程技术研究中心、上海华科智谷人工智能研究院申报主办。中科院院士乔红、上海市科学技术委员会副主任谢文澜、市委军民融合办发展规划处处长叶先扬、上海市科学技术委员会研发基地建设与管理处处长毕聪、上海市经济和信息化委员会软件和信息服务业处副处长
        何炜、中电科数字集团江波书记出席本次会议。来自百度、京东、GE、商汤科技，联影医疗、先惠技术、上海汽车城、雅迅网络、华讯网络、华科智谷科技、丰蕾科技、浙江电力、上汽车变速器、卡斯柯，熙重电子等企业专家出席会议并做专业报告和参加座谈。
      </div>
      <div class="imgGroup pic">
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/group1.png" alt="" />
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/group2.png" alt="" />
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/group3.png" alt="" />
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/group4.png" alt="" />
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/group5.png" alt="" />
      </div>
    </div>
    <div class="newsBox">
      <div class="left">
        <div>本次论坛的召集人，中科院院士、上海华科智谷人工智能研究院院长何积丰院士在会前给出以下寄语：</div>
        <div class="noindent">
          近年来以机器学习为代表的人工智能技术，在社会治理，国民经济发展和民生改善几个核心领域取得了显著的成就，并有不少成功的案例。人工智能发展得益于数据，算力和算法三大要素的改善，更大的发展驱动力来自于社会经济生活中所提供的实际需求和应用场景。
          上海市历来重视人工智能企业的发展。通过本次论坛，我们希望人工智能产业投身新赛道，构建发展新优势，强化增长新支撑。在关键领域和核心技术上抢占国际制高点，激荡发展新动能，为促进经济社会更快更好的发展做出贡献。
        </div>
      </div>
      <div class="right">
        <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/speech.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  // font-size: 1rem;
  .title {
    text-align: center;
    font-size: 1.5rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin-bottom: 1.875rem;
  }
  .spance {
    margin-top: 3.6875rem;
  }
  .pic {
    margin-top: 1.9375rem;
    margin-bottom: 3.0625rem;
  }
  .newsBox {
    display: flex;
    align-items: center;

    .left {
      width: 34.8125rem;
      text-indent: 2em;
      font-size: 1.25rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;

      line-height: 2.5rem;
      span {
        color: #e64040;
      }
      .noindent {
        text-indent: 0;
      }
    }
    .right {
      width: 28.4375rem;
      height: 16.25rem;
      margin-left: 2.875rem;
      img {
        width: 100%;
      }
    }
  }
  .artile {
    text-indent: 2em;
    font-weight: 400;
    color: #333333;
    line-height: 2.5rem;
    font-size: 1.25rem;

    span {
      color: #e64040;
    }
    .imgGroup {
      display: flex;
      justify-content: space-between;
      img {
        width: 13rem;
      }
    }
  }
}
</style>
