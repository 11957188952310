<template>
  <div class="content">
    <div class="artile">
      <!-- <div class="quotation">“</div> -->
      <div class="description">中国科学院院士何积丰发起并成立了上海华科智谷人工智能研究院，何院士担任理事长兼院长。研究院基于智能科学、可信软件、智能信息处理等人工智能技术领域的成果池与资源库，由院士和专家团队领衔，旨在促进人工智能通用共性技术的研发并取得一批创新性应用研究成果，为智能产业及企业提供智能传感与信息融合、智能认知与智能计算、可信计算与自主决策等方向的技术产品和解决方案。</div>
      <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/cirtle.png" alt="" />
    </div>
    <div class="artile">
      <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/hand.png" alt="" />
      <!-- <div class="quotation">“</div> -->
      <div class="description">何积丰院士长期从事计算机软件理论及应用研究，是程序统一理论的创立者、数据精化完备理论的奠基者,、可信软件设计理论与技术的开拓者。何积丰院士在世界上首次提出“可信人工智能”，自此世界各地开始广泛关注人工智能的安全可信问题。</div>
    </div>
    <div class="artile">
      <div class="description">
        曾担任国家自然科学基金委重大研究计划，科技部973计划、863计划主题项目首席科学家，领衔国家自然科学基金委创新研究群体，出版英文专著2部，在国际期刊和会议上发表论文160余篇，他引4000余次。以唯一完成人荣获国家自然科学奖二等奖和上海市科技进步奖一等奖各1项；以项目第一完成人荣获上海市科技进步奖特等奖；
        以第一完成人获省部级科技进步奖与科技成果奖一等奖4项，两次荣获英国女王先进技术奖，以及获得法国骑士勋章。
      </div>
      <img src="https://jeatron-sun.oss-cn-shanghai.aliyuncs.com/img/test/img/assets/person.png" alt="" />
    </div>
    <div class="artile artile1">
      <img src="../../../assets/book.jpg" alt="" />
      <!-- <div class="quotation">“</div> -->
      <div>
        <div class="description">Theories of Programming and Formal Methods; Essays Dedicated to Jifeng He on the Occasion of his 80th Birthday </div>
        <div class="description">Festschrift Jifeng He, LNCS 14080, On-line Access: </div>
        <a href="https://link.springer.com/book/10.1007/978-3-031-40436-8" class="borderStyle">点击查看</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-size: 1rem;
  color: #333333;
  .artile {
    display: flex;
    align-items: center;
    margin-top: 4.0625rem;
    margin-bottom: 4.625rem;
    line-height: 2.5rem;
    img {
      width: 10.625rem;
      height: 10.625rem;
      margin-left: 2.9375rem;
    }
  }
  .artile1 {
    align-items: flex-start;
  }
  .quotation {
    font-size: 5.1875rem;
    font-weight: 400;
    color: #eeeeee;
    align-self: flex-start;
    padding-top: 2.5rem;
    text-align: right;
  }
  .description {
    text-indent: 2em;
    margin-left: 0.625rem;
    font-size: 1.25rem;
  }
  .markcolor {
    color: #e64040;
  }
  .borderStyle {
    display: block;
    width: 7.62rem;
    height: 2.125rem;
    line-height: 2.125rem;
    text-decoration: none;
    border: 1px solid #1547b0;
    margin-left: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    margin-top: 1rem;
    color: #1547b0;
  }
}
</style>
